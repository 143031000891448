<template>
	<div>
		<v-row>
			<v-col>
				<h2>ご協力ありがとうございました</h2>
				<p class="mt-5 mb-5 text-subtitle-1">以下のコードをYahoo!クラウドソーシングの画面に入力してください。</p>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="auto">
				<v-alert
					border="left"
					color="blue"
					width="300"
					text
				><span class="text-h3">AABBCCDD</span></v-alert>
			</v-col>
		</v-row>
	</div>
</template>
<script>
export default {
	name: 'SurveyResult',
}
</script>